<template>
  <div>
    <div class="detail_icon">
      <i class="el-icon-arrow-left" @click="backToList"
        ><span>返回列表</span></i
      >
    </div>

    <div class="detail_content">
      <div class="detail_content_phone">
        <div style="margin: 0">
          手机号：{{ statusDetailData.phoneArea }} {{ statusDetailData.phone }}
        </div>
        <div class="detail_image_preview_font">
          {{ statusDetailData.driverSubmitDate }} 发布<span
            >(点击下方图片可查看大图)</span
          ><img src="@/assets/down.png" alt="" />
        </div>
      </div>
      <div class="detail_image_preview">
        <div class="img_flex">
          <el-image
            v-for="(item, index) in imgArr"
            :key="index"
            :src="item"
            :preview-src-list="getPreviewImgList(index, imgArr)"
          >
          </el-image>
        </div>
      </div>
      <div class="detail_content_info">
        <div style="margin-right: 100px">
          <div>
            真实姓名：<span>{{ statusDetailData.realName }}</span>
          </div>
          <div>
            身份证号：<span>{{ statusDetailData.realId }}</span>
          </div>
          <div>
            驾驶证注册时间：<span>{{ statusDetailData.licenseGetDate }}</span>
          </div>
          <div>
            驾驶证失效时间：<span>{{
              statusDetailData.licenseExpireDate
            }}</span>
          </div>
          <div>
            行驶证注册时间：<span>{{ statusDetailData.drivingRegDate }}</span>
          </div>
        </div>
        <div>
          <div>
            车辆所有人：<span>{{ statusDetailData.carOwner }}</span>
          </div>
          <div>
            车牌号码：<span>{{ statusDetailData.carNumber }}</span>
          </div>
          <div>
            车辆型号：<span>{{ statusDetailData.car }}</span>
          </div>
          <div>
            车辆类型：<span>{{ statusDetailData.carType }}</span>
          </div>
          <div>
            使用性质：<span>{{ statusDetailData.carNature }}</span>
          </div>
        </div>
      </div>
      <div class="detail_content_btn">
        <el-button
          type="success"
          round
          @click="confirm"
          style="margin-right: 50px"
          >通 过</el-button
        >
        <el-button type="danger" round @click="dialogFormVisible = true"
          >拒 绝</el-button
        >
      </div>
      <!-- 拒绝审核通过dialog -->
      <el-dialog title="拒绝原因" :visible.sync="dialogFormVisible">
        <el-form>
          <el-form-item label="请输入未通过审核的原因：">
            <el-input v-model="reason" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Failed()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
export default {
  data() {
    return {
      statusDetailData: {},
      imgArr: [],

      dialogFormVisible: false, // 拒绝审核通过
      reason: "",
    };
  },

  mounted() {
    const opt = {
      url: reqUrl.getAuditingDetail,
      method: "POST",
      params: localStorage.getItem("ownerListStatusUserId"),
      resFunc: (res) => {
        this.statusDetailData = res.data.data;
        this.imgArr.push(
          res.data.data.driverLicencePictureMain,
          res.data.data.drivingLicensePictureMain,
          res.data.data.carFrontPicture,
          res.data.data.carBehindPicture
        );
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    backToList() {
      this.$router.back(); //返回表格页
    },

    // 放大图片
    getPreviewImgList(index, imgArr) {
      let arr = [];
      let i = 0;
      for (i; i < imgArr.length; i++) {
        arr.push(imgArr[i + index]);
        if (i + index >= imgArr.length - 1) {
          index = 0 - (i + 1);
        }
      }
      return arr;
    },

    // 通过审核按钮弹窗提示
    confirm() {
      this.$confirm("你确认要通过审核吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          const opt = {
            url: reqUrl.examineDetailDriver,
            method: "POST",
            params: JSON.stringify({
              examinBy: "",
              nonce: "",
              phone: this.statusDetailData.phone,
              phoneArea: this.statusDetailData.phoneArea,
              sign: "",
              token: "",
              status: 2,
              statusMsg: "",
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "审核通过!",
                });
                this.$router.push("/AuditStatusList");
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },

    // 未通过审核按钮弹窗提示
    Failed() {
      this.dialogFormVisible = false;

      const opt = {
        url: reqUrl.examineDetailDriver,
        method: "POST",
        params: JSON.stringify({
          examinBy: "",
          nonce: "",
          phone: this.statusDetailData.phone,
          phoneArea: this.statusDetailData.phoneArea,
          sign: "",
          token: "",
          status: 3,
          statusMsg: this.reason,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.$router.push("/AuditStatusList");
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
}
.detail_icon {
  height: 40px;

  span {
    font-size: 14px;
  }
}

.detail_content {
  margin-left: 15px;
  color: #333;
  font-size: 14px;

  .detail_content_phone {
    display: flex;
    align-items: center;

    .detail_image_preview_font {
      display: flex;
      align-items: center;
      align-items: center;
      margin-left: 50px;
      color: #475669;

      img {
        width: 18px;
        height: 25px;
        margin-left: 10px;
      }
    }
  }

  .detail_image_preview {
    margin-top: 8px;
    .img_flex {
      display: flex;
      justify-content: space-between;
      margin: 0;

      .el-image {
        width: 380px;
        height: 300px;
        margin-right: 5px;
      }
    }

    span {
      font-size: 12px;
    }
  }

  .detail_content_info {
    width: 100%;
    margin: 8px auto;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    line-height: 30px;
  }

  .detail_content_btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .el-button {
      width: 200px;
    }
  }
}
</style>
